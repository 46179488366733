var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('div',{staticClass:"row fadeInUp",attrs:{"role":"main"}},[_c('div',{staticClass:"col-12 col-md-3"},[_c('ConfigurationNav')],1),_c('div',{staticClass:"col-12 col-md-9"},[_c('div',{staticClass:"card border-0 shadow-sm"},[_c('div',{staticClass:"card-header"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Edit meta field collection ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"control-label text-capitalize",attrs:{"for":"type"}},[_vm._v("Type")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.type),expression:"form.type"}],staticClass:"form-control",attrs:{"id":"type","type":"text","name":"type","disabled":""},domProps:{"value":(_vm.form.type)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "type", $event.target.value)}}})])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"control-label text-capitalize",attrs:{"for":"source"}},[_vm._v("Source")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.source),expression:"form.source"}],staticClass:"form-control",attrs:{"id":"source","type":"text","name":"source","disabled":""},domProps:{"value":(_vm.form.source)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "source", $event.target.value)}}})])]),_c('div',{staticClass:"form-row"},[_c('p',{staticClass:"mb-0 h4"},[_vm._v(" Fields ")]),_c('data-table',{attrs:{"headers":_vm.headers(),"data":_vm.form.fields,"sort-url":"/order/dynamicFieldCollection"},scopedSlots:_vm._u([{key:"key",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.key)+" ")]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.name),expression:"item.name"}],staticClass:"form-control",attrs:{"id":"name","type":"text"},domProps:{"value":(item.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "name", $event.target.value)}}})]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(item.type),expression:"item.type"}],staticClass:"custom-select",attrs:{"id":item.key + 'Type'},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(item, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v(" Choose type ")]),_vm._l((_vm.types),function(type){return _c('option',{key:type,domProps:{"value":type}},[_vm._v(" "+_vm._s(type)+" ")])})],2)]}},{key:"search",fn:function(ref){
var item = ref.item;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(item.search),expression:"item.search"}],staticClass:"form-control",attrs:{"id":item.key + 'Search'},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(item, "search", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.fieldTypes),function(opt){return _c('option',{key:opt.key,domProps:{"value":opt.key}},[_vm._v(" "+_vm._s(opt.name)+" ")])}),0)]}},{key:"visible",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"custom-control custom-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.visible),expression:"item.visible"}],staticClass:"custom-control-input",attrs:{"id":item.key + 'Visibility',"type":"checkbox"},domProps:{"checked":Array.isArray(item.visible)?_vm._i(item.visible,null)>-1:(item.visible)},on:{"change":function($event){var $$a=item.visible,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "visible", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "visible", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "visible", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":item.key + 'Visibility'}},[_vm._v(" ")])])]}}])})],1)]),_c('div',{staticClass:"card-footer bg-white"},[_c('ButtonLoading',{class:'btn btn-secondary float-right',attrs:{"loading":_vm.loading,"title":'Save'},nativeOn:{"click":function($event){return _vm.submitForm.apply(null, arguments)}}}),(_vm.isSearchMapped)?_c('span',{staticClass:"text-warning mr-4 mt-2 float-right"},[_vm._v("Changing the search mapping could affect your views.")]):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }