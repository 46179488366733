<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <ConfigurationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <p class="mb-0">
              Edit meta field collection
            </p>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-12">
                <label for="type" class="control-label text-capitalize"
                  >Type</label
                >
                <input
                  id="type"
                  v-model="form.type"
                  type="text"
                  name="type"
                  class="form-control"
                  disabled
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-12">
                <label for="source" class="control-label text-capitalize"
                  >Source</label
                >
                <input
                  id="source"
                  v-model="form.source"
                  type="text"
                  name="source"
                  class="form-control"
                  disabled
                />
              </div>
            </div>
            <div class="form-row">
              <p class="mb-0 h4">
                Fields
              </p>
              <data-table
                :headers="headers()"
                :data="form.fields"
                sort-url="/order/dynamicFieldCollection"
              >
                <template #key="{ item }">
                  {{ item.key }}
                </template>
                <template #name="{ item }">
                  <input
                    id="name"
                    v-model="item.name"
                    class="form-control"
                    type="text"
                  />
                </template>
                <template #type="{ item }">
                  <select
                    :id="item.key + 'Type'"
                    v-model="item.type"
                    class="custom-select"
                  >
                    <option value="">
                      Choose type
                    </option>
                    <option v-for="type in types" :value="type" :key="type">
                      {{ type }}
                    </option>
                  </select>
                </template>
                <template #search="{ item }">
                  <select
                    :id="item.key + 'Search'"
                    v-model="item.search"
                    class="form-control"
                  >
                    <option
                      v-for="opt in fieldTypes"
                      :key="opt.key"
                      :value="opt.key"
                    >
                      {{ opt.name }}
                    </option>
                  </select>
                  <!-- <div v-if="item.search && item.search.indexOf('date') === 0">
                    <input
                      :id="item.key + 'Format'"
                      v-model="item.format"
                      class="form-control mt-3"
                      type="text"
                    />
                    <p class="text-muted">
                      please enter your date format.<br />
                      You can see how to enter<br />
                      the format
                      <a
                        href="https://www.php.net/manual/en/datetime.format.php#refsect1-datetime.format-parameters"
                        target="_blank"
                        >here</a
                      >
                    </p>
                  </div> -->
                </template>
                <template #visible="{ item }">
                  <div class="custom-control custom-switch">
                    <input
                      :id="item.key + 'Visibility'"
                      v-model="item.visible"
                      type="checkbox"
                      class="custom-control-input"
                    />
                    <label
                      class="custom-control-label"
                      :for="item.key + 'Visibility'"
                      >&nbsp;</label
                    >
                  </div>
                </template>
              </data-table>
            </div>
          </div>
          <div class="card-footer bg-white">
            <ButtonLoading
              :loading="loading"
              :title="'Save'"
              :class="'btn btn-secondary float-right'"
              v-on:click.native="submitForm"
            />
            <span
              v-if="isSearchMapped"
              class="text-warning mr-4 mt-2 float-right"
              >Changing the search mapping could affect your views.</span
            >
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import DataTable from "@/components/DataTable/DataTable.vue";
import Default from "@/layouts/default.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import Spinner from "@/components/UI/Spinner.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  components: {
    DataTable,
    Default,
    ConfigurationNav,
    Spinner,
    ButtonLoading,
  },
  async beforeRouteEnter(to, from, next) {
    const response = await jsonAPI.get(
      "order/dynamicFieldCollection/" + to.params.id
    );
    next((vm) => {
      vm.form = response.data;
      if (vm.form.fields) {
        vm.form.fields.forEach((field) => {
          if (field.search) {
            vm.isSearchMapped = true;
          }
        });
      } else {
        vm.form.fields = [];
      }
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      types: ["string", "array", "int", "date", "date time"],
      isSearchMapped: false,
      searchHasChanged: false,
      form: {
        id: null,
        type: null,
        source: null,
        fields: [],
        organization_id: null,
      },
      fieldTypes: [
        {
          name: "Empty",
          key: "",
        },
        {
          name: "Text 1",
          key: "text_1",
        },
        {
          name: "Text 2",
          key: "text_2",
        },
        {
          name: "Text 3",
          key: "text_3",
        },
        {
          name: "Text 4",
          key: "text_4",
        },
        {
          name: "Text 5",
          key: "text_5",
        },
        {
          name: "Text 6",
          key: "text_6",
        },
        {
          name: "Text 7",
          key: "text_7",
        },
        {
          name: "Text 8",
          key: "text_8",
        },
        {
          name: "Text 9",
          key: "text_9",
        },
        {
          name: "Text 10",
          key: "text_10",
        },
        {
          name: "Number 1",
          key: "number_1",
        },
        {
          name: "Number 2",
          key: "number_2",
        },
        {
          name: "Number 3",
          key: "number_3",
        },
        {
          name: "Number 4",
          key: "number_4",
        },
        {
          name: "Number 5",
          key: "number_5",
        },
        {
          name: "Date 1",
          key: "date_1",
        },
        {
          name: "Date 2",
          key: "date_2",
        },
        {
          name: "Date 3",
          key: "date_3",
        },
        {
          name: "Date 4",
          key: "date_4",
        },
        {
          name: "Date 5",
          key: "date_5",
        },
        {
          name: "Boolean 1",
          key: "boolean_1",
        },
        {
          name: "Boolean 2",
          key: "boolean_2",
        },
        {
          name: "Boolean 3",
          key: "boolean_3",
        },
        {
          name: "Boolean 4",
          key: "boolean_4",
        },
        {
          name: "Boolean 5",
          key: "boolean_5",
        },
      ],
    };
  },
  methods: {
    headers() {
      return [
        { title: "Key", key: "key", sortable: false },
        {
          title: "Field name",
          key: "name",
          sortable: false,
          tooltip: "How data is shown",
        },
        {
          title: "Data type",
          key: "type",
          sortable: false,
          tooltip: "The field name that will be shown in views.",
        },
        {
          title: "Filtration type",
          key: "search",
          sortable: false,
          tooltip: "How data is stored, handled and managed in filters.",
        },
        { title: "Visible", key: "visible", sortable: false },
      ];
    },
    submitForm: function() {
      this.loading = true;
      const fields = [];
      let valid = true;
      this.form.fields.forEach((elem) => {
        if (elem.search) {
          if (fields.includes(elem.search)) {
            valid = false;
          }
          fields.push(elem.search);
        }
      });
      if (valid) {
        jsonAPI
          .put("/order/dynamicFieldCollection/" + this.form.id, this.form)
          .then(() => {
            window.Bus.$emit("flash-message", {
              text: "Custom fields saved",
              type: "success",
            });
          })
          .catch((error) => {
            Object.keys(error.response.data.errors).forEach((key) => {
              this.$validator.errors.add({
                field: key,
                msg: error.response.data.errors[key][0],
              });
            });
            window.Bus.$emit("flash-message", {
              text: "error main",
              type: "error",
            });
          });
      } else {
        window.Bus.$emit("flash-message", {
          text:
            "Please make sure a field type is not assigned to multiple fields",
          type: "error",
        });
      }
      this.loading = false;
    },
  },
};
</script>
